import {
  NUM_REG,
  //  PWD_REG
} from "@/utils/reg";
import { mapGetters, mapMutations } from "vuex";
import { getToken } from "@lib/index";
import IHeader from "@component/IHeader/index.vue";
import IFooter from "@component/IFooter/index.vue";
export default {
  data() {
    // 验证手机号
    const checkAcc = (rules, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      } else if (!NUM_REG.test(value)) {
        callback(new Error("手机号格式错误!"));
      } else {
        callback();
      }
    };
    // 验证密码
    const checkPwd = (rules, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      }
      // else if (!PWD_REG.test(value)) {
      //   callback(new Error("请输入6-12位字符包括字母、数字及下划线"));
      // }
      else {
        callback();
      }
    };
    return {
      // 登录表单
      loginForm: {
        telphone: "",
        password: "",
      },
      loginRules: {
        telphone: { validator: checkAcc, trigger: "blur" },
        password: { validator: checkPwd, trigger: "blur" },
      },
      region: 0,
      dialogShow: false,
    };
  },
  computed:{
    ...mapGetters(['tenementList', 'userInfo'])
  },
  methods: {
    ...mapMutations(["storeToken", "storeUserInfo", "storeTenement", "storeTenementList"]),
    // 去注册
    goRegister() {
      this.$router.push("/register");
    },
    // 忘记密码
    goReset() {
      this.$router.push("/reset");
    },
    //   确认登录表单信息
    submitloginForm(loginForm) {
      this.$refs[loginForm].validate(async (valid) => {
        if (valid) {
          let { status, result } = await getToken({
            loginName: this.loginForm.telphone,
            password: this.loginForm.password,
          });
          if (status === "success") {
            let { token, userInfo, tenementList } = result;
            this.storeToken(token);
            this.storeUserInfo(userInfo);
            this.$message.success("登录成功");
            if (userInfo.categoryId === "developer") {
              this.storeTenementList(tenementList);
              if(tenementList.length>1){
                this.dialogShow = true
              }else{
                if(tenementList.length===1){
                  this.storeTenement(tenementList[0]);
                }
                this.$router.push({name: 'accountList'});
              }
            } else {
              this.$router.push({name: 'solutionList'});
            }
          } else {
            this.$message.error(result.error_msg);
            this.loginForm.password = "";
          }
        } else {
          return false;
        }
      });
    },
    onEnter(){
      this.storeTenement(this.tenementList[this.region]);
      if (this.userInfo.categoryId === "developer") {
        this.$router.push({name: 'accountList'});
      } else {
        this.$router.push({name: 'solutionList'});
      }
    }
  },
  components: {
    [IHeader.name]: IHeader,
    [IFooter.name]: IFooter,
  }
};
