/*
 * @Author: your name
 * @Date: 2022-04-11 14:00:24
 * @LastEditTime: 2022-04-11 14:17:55
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/utils/reg.js
 */
/**
 * 表单验证
 *
 */

// 手机号验证
export var NUM_REG = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
// 密码验证
// export var PWD_REG = /^[0-9a-zA-Z_]{1,}$/;
export var PWD_REG = /^[\w]{6,18}$/;
// 企业名称 ss\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/;
// 统一信用代码
export var CODE_REG = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
// 短信验证码
export var REMARK_REG = /^\d{6}$/;
